module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-4b80a34dbd/4/buildhome/.yarn_cache/gatsby-plugin-gatsby-cloud-npm-4.19.0-2129f5d294-40cdf7dadd.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-material-ui-virtual-19c2263431/4/buildhome/.yarn_cache/gatsby-plugin-material-ui-npm-4.1.0-2938ee1c08-f1d38d2e50.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/emotion-cache-props"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4ab7ee306b/4/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-4.19.0-5c73f87aea-bc47920497.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Overmoon | 721 Investor Dashboard","short_name":"Overmoon","start_url":"/","background_color":"#367DE8","theme_color":"#367DE8","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef10ac479d24d8f8969bdf79445d0564"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-segment-js-virtual-068b8066a7/4/buildhome/.yarn_cache/gatsby-plugin-segment-js-npm-3.7.1-69054e5b34-801e02268b.zip/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"4bAms7mSpAjmgDq9em2fE4e8vzSOvA2H","devKey":"4bAms7mSpAjmgDq9em2fE4e8vzSOvA2H","trackPage":false},
    },{
      plugin: require('../../../.yarn/__virtual__/@sentry-gatsby-virtual-529ea5d36d/4/buildhome/.yarn_cache/@sentry-gatsby-npm-6.19.7-98559ac9b4-bf2192507c.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://cb5d728ad4a44734bced51daa20f0ca8@o925641.ingest.sentry.io/5890043","sampleRate":0,"environment":"staging"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-fix-fouc-virtual-4fabe247d7/4/buildhome/.yarn_cache/gatsby-plugin-fix-fouc-npm-1.0.4-dd5d8049c1-3d2ee50633.zip/node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-25598fae33/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
